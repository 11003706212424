import React, { useEffect, useState } from 'react';

import './TrustPilot.sass';


const TrustBoxContainer = ({ locale }) => {

  const [showWidget, setShowWidget] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowWidget(true), 10000);
  }, []);

  const isDk = locale === 'da-DK';
  const url = `https://${ isDk ? 'dk' : 'www' }.trustpilot.com/review/www.lokalebasen.dk`;


  return (
    <div className={`trustpilot-wrapper ${showWidget ? 'visible' : ''}`}>
      <div
        id="trustbox"
        className="trustpilot-widget"
        data-locale={locale}
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="4bdc4ef6000064000505acdd"
        data-style-height="20px"
        data-style-width="100%"
        data-style-alignment="center"
        data-theme="light">
        <a href={url} target="_blank" rel="noopener">Trustpilot</a>
      </div>
    </div>
  )
};


export default TrustBoxContainer;
