import React, { Component, Fragment } from 'react';
import { withFormik } from 'formik';
import Yup from 'yup';

import Client from '../../utils/Client';
import { validationPatterns } from '../../utils/validationPatterns';
import { SvgIco } from "../SharedComponents/Icons";
import Loader from "../SharedComponents/Loader";


class InfoForm extends Component {
  state = {
    subFormCollapsed: true,
    collapsibleWrapperFullHeight: null,
    collapsibleWrapperSubHeight: null,
    // jeudanReadMore: false,
    isShowingModal: false,
  };

  componentDidMount() {
    const subHeight = this.formFields.clientHeight - this.subFrom.clientHeight;

    this.setState({
      collapsibleWrapperFullHeight: 1000,
      collapsibleWrapperSubHeight: subHeight,
    });
  }

  uncollapseSubForm = () => {
    this.state.subFormCollapsed && this.setState({ subFormCollapsed: false });
  };

  handleFieldBlur = (e) => {
    this.props.handleBlur(e);
    this.props.disableArrowWhenFocusInput
      ? this.props.disableArrowWhenFocusInput(false)
      : null;
    // storeValidationFails(this, 'order');
  };

  // openReadMoreJeudan = () => this.setState({ jeudanReadMore: true });

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      locationOrdered,
      isSubmittingOrder,
      // isJeudan,
      handleSubmit,
      buttonId,
      handleInputChange,
      setFieldValue,
    } = this.props;

    const {
      subFormCollapsed,
      collapsibleWrapperFullHeight,
      collapsibleWrapperSubHeight,
      // jeudanReadMore,
    } = this.state;

    const height = subFormCollapsed
      ? collapsibleWrapperSubHeight
      : collapsibleWrapperFullHeight;
    const maxHeight = { maxHeight: `${locationOrdered ? 0 : height}px` };
    // const checkboxClasses = classNames('jeudan-policy-checkbox__wrapper', {
    //   checked: values.jeudan_policy,
    //   error: touched.jeudan_policy && errors.jeudan_policy,
    // });


    const submitButtonContent =
      <Fragment>
        { locationOrdered && <SvgIco name="check" size={25} /> }
        { isSubmittingOrder && <Loader size={18} left={7} top={15} /> }
        <span>
          { locationOrdered
            ? I18n.t('apps.lb_showroom.detailed.order_form.info_button_one_send')
            : I18n.t('apps.lb_showroom.detailed.order_form.info_button_one')
          }
        </span>
      </Fragment>;


    return (
      <div className="form-section">
        <form onSubmit={handleSubmit} noValidate>
          {/* here we check does this field was filled by bot */}
          <input
            id="fifth_element"
            type="text"
            name="fifth_element"
            className="invisible"
            value={values.fifth_element}
            onPaste={handleChange}
            onChange={handleChange}
            onFocus={this.uncollapseSubForm}
            onBlur={this.handleFieldBlur}
          />

          <div className="order-form__collapsible-wrapper form-collapsible-wrapper">
            <div
              className="form-collapsible"
              ref={(elem) => (this.formFields = elem)}
              style={maxHeight}
            >
              <div className="input-wrapper">
                <div className="form-group">
                  <input
                    id="name"
                    type="text"
                    value={values.name}
                    onChange={(e) => {
                      handleChange(e);
                      handleInputChange(e);
                    }}
                    onBlur={this.handleFieldBlur}
                    onFocus={() => { this.uncollapseSubForm() }}
                    className={`form-control order-form__input
                      ${ values.name !== '' ? ' not-empty' : ' empty'}
                      ${ touched.name ? (errors.name ? ' error' : ' is-valid order-input-valid') : ''}`
                    }
                  />

                  <span className="floating-label">
                    { I18n.t('activemodel.attributes.provider_service_request.person_name') }*
                  </span>

                  <div className="validation-wrapper" />

                  <div className="validation-message">
                    { touched.name && errors.name ? errors.name : null }
                  </div>
                </div>

                <div className="form-group">
                  <input
                    id="email"
                    type="email"
                    value={values.email}
                    onChange={(e) => {
                      handleChange(e);
                      handleInputChange(e);
                    }}
                    onFocus={() => {
                      this.uncollapseSubForm();
                    }}
                    onBlur={this.handleFieldBlur}
                    className={`form-control order-form__input
                        ${values.email !== '' ? 'not-empty' : 'empty'}
                        ${
                          touched.email
                            ? errors.email
                              ? 'error'
                              : 'is-valid order-input-valid'
                            : null
                        }`}
                  />
                  <span className="floating-label">
                    {I18n.t(
                      'activemodel.attributes.provider_service_request.email',
                    )}
                    *
                  </span>
                  <div className="validation-wrapper" />
                  <div className="validation-message">
                    {touched.email && errors.email ? errors.email : null}
                  </div>
                </div>

                <div className="form-sub" ref={(elem) => (this.subFrom = elem)}>
                  <div className="form-group">
                    <input
                      id="company"
                      type="text"
                      value={values.company}
                      onChange={(e) => {
                        const value = e.target.value.trimStart().replace(/\s\s+/g, ' ');

                        setFieldValue('company', value);
                        handleInputChange(e, value);
                      }}
                      onBlur={this.handleFieldBlur}
                      className={`form-control order-form__input
                          ${values.company !== '' ? 'not-empty' : 'empty'}
                          ${
                            touched.company
                              ? errors.company
                                ? 'error'
                                : 'is-valid order-input-valid'
                              : null
                          }`}
                    />
                    <span className="floating-label">
                      {I18n.t(
                        'activemodel.attributes.provider_service_request.company_name',
                      )}
                      *
                    </span>
                    <div className="validation-wrapper" />
                    <div className="validation-message">
                      {touched.company && errors.company
                        ? errors.company
                        : null}
                    </div>
                  </div>

                  <div className="form-group">
                    <input
                      id="phone"
                      type="tel"
                      value={values.phone}
                      onChange={(e) => {
                        const value = e.target.value.trimStart().replace(/\s\s+/g, ' ');

                        setFieldValue('phone', value);
                        handleInputChange(e, value);
                      }}
                      onBlur={this.handleFieldBlur}
                      className={`form-control order-form__input
                          ${values.phone !== '' ? 'not-empty' : 'empty'}
                          ${
                            touched.phone
                              ? errors.phone
                                ? 'error'
                                : 'is-valid order-input-valid'
                              : null
                          }`}
                    />
                    <span className="floating-label">
                      {I18n.t(
                        'activemodel.attributes.provider_service_request.phone_number_order_form',
                      )}
                      *
                    </span>
                    <div className="validation-wrapper" />
                    <div className="validation-message">
                      {touched.phone && errors.phone ? errors.phone : null}
                    </div>
                  </div>

                  <div className="form-group">
                    <textarea
                      id="comment"
                      type="text"
                      resize="none"
                      value={values.comment}
                      onChange={(e) => {
                        handleChange(e);
                        handleInputChange(e);
                      }}
                      onBlur={this.handleFieldBlur}
                      className={`form-control order-form__textarea
                          ${values.comment ? 'not-empty' : 'empty'}`}
                    />
                    <span className="floating-label disappear-label">
                      {I18n.t(
                        'activemodel.attributes.provider_service_request.your_question',
                      )}{' '}
                      (
                      {I18n.t(
                        'activemodel.attributes.provider_service_request.your_option',
                      )}
                      )
                    </span>
                    <div className="validation-wrapper" />
                  </div>

                  {/*{ isJeudan && (*/}
                  {/*  <div className="form-group jeudan-policy-checkbox">*/}
                  {/*    <div className={checkboxClasses}>*/}
                  {/*      <input*/}
                  {/*        name="jeudan_policy"*/}
                  {/*        type="checkbox"*/}
                  {/*        value={values.jeudan_policy}*/}
                  {/*        id="jeudan_policy"*/}
                  {/*        onChange={handleChange}*/}
                  {/*        onBlur={this.handleFieldBlur}*/}
                  {/*      />*/}
                  {/*      <div className="jeudan-policy-checkbox__label">*/}
                  {/*        <SvgIco name="check" size={15} />*/}
                  {/*        {jeudanReadMore ? (*/}
                  {/*          <div*/}
                  {/*            dangerouslySetInnerHTML={{*/}
                  {/*              __html: I18n.t(*/}
                  {/*                'activemodel.attributes.provider_service_request.jeudan_privacy_policy_full',*/}
                  {/*              ),*/}
                  {/*            }}*/}
                  {/*            className="jeudan-policy-checkbox__label-content"*/}
                  {/*          />*/}
                  {/*        ) : (*/}
                  {/*          <div>*/}
                  {/*            {I18n.t(*/}
                  {/*              'activemodel.attributes.provider_service_request.jeudan_privacy_policy_short',*/}
                  {/*            )}*/}
                  {/*            <span*/}
                  {/*              onClick={this.openReadMoreJeudan}*/}
                  {/*              className="jeudan-policy-checkbox__label-read-more-link"*/}
                  {/*            >*/}
                  {/*              {I18n.t('generic.read_more')}.*/}
                  {/*            </span>*/}
                  {/*          </div>*/}
                  {/*        )}*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*    <div className="validation-message validation-message--jeudan">*/}
                  {/*      {touched.jeudan_policy && errors.jeudan_policy*/}
                  {/*        ? errors.jeudan_policy*/}
                  {/*        : null}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </div>
              </div>
            </div>
          </div>

          <div className="form-button-wrapper order-form__button-wrapper" id={buttonId}>
            <div>
              { !subFormCollapsed
                ?
                  <button
                    className={`order-form__button ${locationOrdered ? 'active' : '' }`}
                    disabled={isSubmittingOrder}
                    onClick={this.uncollapseSubForm}>
                    { submitButtonContent }
                  </button>
                :
                  <div
                    className={`order-form__button ${locationOrdered ? 'active' : '' }`}
                    onClick={this.uncollapseSubForm}>
                    { submitButtonContent }
                  </div>
              }
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const { nameI18n, emailI18n, companyI18n, phoneI18n } =
  Client.getI18nAttrNames();
const i18nRequiredKey =
  'apps.lb_showroom.detailed.order_form.validation.field_required';
const i18nMinNumberKey =
  'apps.lb_showroom.detailed.order_form.validation.min_number';
const i18nSymbolsLimitErrorMessage = I18n.t(
  'apps.lb_showroom.misc.scoped_form.max_symbols_limit',
  { symbols_limit: 254 },
);
const i18nSymbolsEmailLimitErrorMessage = I18n.t(
  'apps.lb_showroom.misc.scoped_form.max_symbols_limit',
  { symbols_limit: 100 },
);
const i18nAtLeast8Digits =
  'apps.lb_showroom.detailed.order_form.validation.at_least_8_digits';

const Form = withFormik({
  mapPropsToValues: (bag) => {
    const { fifth_element, name, phone, email, company, option_parking_lots } =
      Client.getClientInfo();
    return {
      name,
      email,
      phone: phone ? phone : bag.userCountryPhoneCode,
      company,
      option_parking_lots,
      fifth_element,
      // jeudan_policy: false,
      // isJeudan: bag.isJeudan,
    };
  },
  validationSchema: Yup.object().shape({
    fifth_element: Yup.string(),
    name: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(
        validationPatterns.name,
        I18n.t('apps.lb_showroom.misc.scoped_form.name.invalid'),
      )
      .required(I18n.t(i18nRequiredKey, { field: nameI18n })),
    email: Yup.string()
      .matches(
        validationPatterns.symbolsEmailLimit,
        i18nSymbolsEmailLimitErrorMessage,
      )
      .email(
        I18n.t('apps.lb_showroom.detailed.order_form.validation.wrong_email'),
      )
      .required(I18n.t(i18nRequiredKey, { field: emailI18n })),
    company: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(
        validationPatterns.company,
        I18n.t('apps.lb_showroom.misc.scoped_form.company.invalid'),
      )
      .required(I18n.t(i18nRequiredKey, { field: companyI18n })),
    phone: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .min(8, I18n.t(i18nMinNumberKey, { field: phoneI18n }))
      .matches(
        validationPatterns.phone,
        I18n.t('apps.lb_showroom.misc.scoped_form.phone.invalid'),
      )
      .test(
        'minDigitsValidation',
        I18n.t(i18nAtLeast8Digits),
        function (value) {
          const { path, createError } = this;
          const phoneIsValid =
            value && value.replace(/[+\-\s()]/g, '').length >= 8;
          return (
            phoneIsValid ||
            !value ||
            createError({ path, message: I18n.t(i18nAtLeast8Digits) })
          );
        },
      )
      .required(I18n.t(i18nRequiredKey, { field: phoneI18n })),
    // isJeudan: Yup.boolean(),
    // jeudan_policy: Yup.boolean().when('isJeudan', {
    //   is: true,
    //   then: Yup.boolean()
    //     .required(
    //       I18n.t(
    //         'activemodel.attributes.provider_service_request.jeudan_privacy_policy_error_message',
    //       ),
    //     )
    //     .oneOf(
    //       [true],
    //       I18n.t(
    //         'activemodel.attributes.provider_service_request.jeudan_privacy_policy_error_message',
    //       ),
    //     ),
    // }),
  }),
  handleSubmit: (values, bag) => {
    // const detailedAnalyticsLabel = 'DetailedView';

    // GoogleAnalytics.submitOrderAttempt(detailedAnalyticsLabel); // added analytics on form submit
    Client.updateClientInfo(values);
    bag.props.submitHandler(values);
  },
  displayName: 'InfoForm', // helps with React DevTools
})(InfoForm);

export default Form;
