import React, { useContext, useEffect, useState, Fragment } from 'react';

import { Context } from "../DetailedViewHeader";
import { placeOrder, showCase, contactProvider, placeUncompletedOrder } from '../../utils/api';
import { camelizeName } from "../../utils/helpers";
import Storage from '../../utils/localStorage';
import Client from '../../utils/Client';
import { sendTrovitLead } from "../helpers/trovit_analitics";
import TrustBoxContainer from '../SharedComponents/TrustPilot';
import DataProtectionModal from '../SharedComponents/DataProtectionModal';
import { SvgIco } from '../SharedComponents/Icons';
import InfoForm from './InfoForm';
import ContactForm from './ContactForm';
import ShowCaseForm from './ShowCaseForm';

import './index.sass';


const OrderForm = () => {
  const {
    contactOrdered,
    contactPath,
    formAuthToken,
    // isJeudan,
    isScraped,
    locationId,
    locationOrdered,
    locationUuid,
    orderPath,
    section,
    selectedFormOption,
    setUpdateAskLandlord,
    setUpdateVirtualTourForm,
    showcaseOrdered,
    showCasePath,
    siteKey,
    setLocationOrdered,
    setContactOrdered,
    setShowcaseOrdered,
    switchSelectedOrderForm,
    trustpilotWidget,
    visitorToken,
    uncompletedOrderEnabled,
    uncompletedOrderValues,
    updateOrderForm,
    updateAskLandlord,
    userCountryPhoneCode,
  } = useContext(Context);

  const [isShowingModal, setIsShowingModal] = useState(false);
  const [isSubmittingInfo, setIsSubmittingInfo] = useState(false);
  const [isSubmittingContact, setIsSubmittingContact] = useState(false);
  const [isSubmittingShowCase, setIsSubmittingShowCase] = useState(false);
  const [formValuesUpd, setFormValuesUpd] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    comment: '',
    company: '',
    email: '',
    name: '',
    phone: '',
  });

  const trustPilotLocale = siteKey === 'dk' ? 'da-DK' : 'en-US';


  useEffect(() => {
    uncompletedOrderValues && Client.updateClientInfo(uncompletedOrderValues);
  }, []);


  useEffect(() => {
    let formName = 'order';

    locationOrdered && !contactOrdered && (formName = 'provider_message');
    contactOrdered && locationOrdered && !showcaseOrdered && (formName = 'book_showcase');
    switchSelectedOrderForm(formName);

  }, [contactOrdered, locationOrdered, showcaseOrdered]);


  useEffect(() => {
    updateOrderForm && setLocationOrdered(true);
  }, [updateOrderForm]);


  useEffect(() => {
    updateAskLandlord && setContactOrdered(true);
  }, [updateAskLandlord]);


  const handleInputChange = (event, value) => {
    event.persist();
    setContactInfo(prevData => ({ ...prevData, [event.target.id]: value || event.target.value }));
    setFormValuesUpd(true);
  };

  const onMouseLeaveHandler = () => {
    if (uncompletedOrderEnabled && formValuesUpd) {
      setFormValuesUpd(false);
      processingUncompletedOrder();
    }
  };

  const processingUncompletedOrder = () => {
    const { name, email, company, phone, comment } = contactInfo;

    if (!uncompletedOrderValues && selectedFormOption === 'order' && name && validateEmail(email)) {
      const data = {
        name,
        company,
        email,
        phone,
        authenticity_token: formAuthToken,
        location_id: locationId,
        context: selectedFormOption,
        comment: comment || '',
      };

      placeUncompletedOrder('/lease/api/frontend/uncompleted_orders', data);
    }
  };

  const validateEmail = (email) => {
    /* eslint-disable max-len, no-useless-escape */
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    /* eslint-disable max-len, no-useless-escape */

    return re.test(String(email).toLowerCase());
  };

  const placeOrderHandler = (params) => {
    params.locationId = locationUuid;
    params.user_token = visitorToken;
    params.name = camelizeName(params.name);
    setIsSubmittingInfo(true);

    placeOrder(orderPath, params)
      .then((response) => {
        sendTrovitLead(siteKey);
        setUpdateVirtualTourForm(true);
        Storage.updateOrderedItem();
        setLocationOrdered(true);
        setIsSubmittingInfo(false);
        window.location.href = response.data.confirmation_page_url;

        if (showcaseOrdered && contactOrdered) {
          switchSelectedOrderForm('order');
          return;
        }
        if (contactOrdered) {
          switchSelectedOrderForm('book_showcase');
          return;
        }
        if (isScraped) {
          switchSelectedOrderForm('order');
          return;
        }
        switchSelectedOrderForm('provider_message');
      })
      .catch((error) => console.error(error));
  };

  const contactFormHandler = (params) => {
    params.locationId = locationId;
    params.user_token = visitorToken;
    params.name = camelizeName(params.name);
    setIsSubmittingContact(true);

    contactProvider(contactPath, params)
      .then((response) => {
        sendTrovitLead(siteKey);
        setUpdateAskLandlord(true);
        Storage.updateContactItem();
        setContactOrdered(true);
        setIsSubmittingContact(false);
        window.location.href = response.data.confirmation_page_url;

        if (!locationOrdered || showcaseOrdered) {
          switchSelectedOrderForm('order');
          return;
        }
        switchSelectedOrderForm('book_showcase');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showCaseHandle = (params) => {
    params.locationId = locationId;
    params.user_token = visitorToken;
    params.name = camelizeName(params.name);
    setIsSubmittingShowCase(true);

    showCase(showCasePath, params)
      .then((response) => {
        sendTrovitLead(siteKey);
        Storage.updateShowcaseItem();
        setShowcaseOrdered(true);
        setIsSubmittingShowCase(false);
        eval(response.data);

        if (locationOrdered && !contactOrdered) {
          switchSelectedOrderForm('provider_message');
          return;
        }
        switchSelectedOrderForm('order');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOptionChange = (changeEvent) =>
    switchSelectedOrderForm(changeEvent.target.value);

  const openInfoModal = () =>
    setIsShowingModal(prevState => !prevState)


  return (
    <div className="order-form">
      <div
        className="order-form__wrapper"
        id="order-form-wrapper"
        onMouseLeave={onMouseLeaveHandler}
      >
        <DataProtectionModal
          isShowingModal={isShowingModal}
          handleClose={() => setIsShowingModal(false)}
          title={I18n.t('apps.lb_showroom.detailed.order_form.data_protection')}
          content={I18n.t('apps.lb_showroom.detailed.order_form.modal.data_protection_body')}
        />

        <section className="order-form__section content-section">
          <div className="order-form__title form-header__title">
            { section !== 'lease'
              ? I18n.t('apps.lb_showroom.detailed.order_form.sale.heading')
              : I18n.t('apps.lb_showroom.detailed.order_form.heading')
            }
          </div>

          <p className="order-form__label">
            { I18n.t('apps.entry_page.search_area.value_points.lease.one_full')}
          </p>

          <div className={`select-option-group${selectedFormOption === 'order' ? ' checked' : ''}`}>
            <input
              checked={selectedFormOption === 'order'}
              className="checkoption-input"
              id="order"
              name="react-tips"
              onChange={handleOptionChange}
              type="radio"
              value="order"
            />
            <label htmlFor="order" className="select-option">
              <span className="option-checkmark" />
              { I18n.t('apps.lb_showroom.detailed.order_form.info_button_one') }
            </label>
          </div>

          { !isScraped && (
            <Fragment>
              <div className={`select-option-group${ selectedFormOption === 'provider_message' ? ' checked' : ''}`}>
                <input
                  checked={selectedFormOption === 'provider_message'}
                  className="checkoption-input"
                  id="provider_message"
                  name="react-tips"
                  onChange={handleOptionChange}
                  type="radio"
                  value="provider_message"
                />
                <label htmlFor="provider_message" className="select-option">
                  <span className="option-checkmark" />
                  { I18n.t('apps.lb_showroom.detailed.order_form.contact_button_one_ab_test') }
                </label>
              </div>

              <div className={`select-option-group${ selectedFormOption === 'book_showcase' ? ' checked' : '' }`}>
                <input
                  checked={selectedFormOption === 'book_showcase'}
                  className="checkoption-input-input"
                  id="book_showcase"
                  name="react-tips"
                  onChange={handleOptionChange}
                  type="radio"
                  value="book_showcase"
                />
                <label htmlFor="book_showcase" className="select-option">
                  <span className="option-checkmark" />
                  { I18n.t('apps.lb_showroom.detailed.order_form.info_button_two_ab_test') }
                </label>
              </div>
            </Fragment>
          )}
        </section>

        <div id="contactFormScrollTarget" />

        { selectedFormOption === 'order' && (
          <section className="order-form__section content-section">
            <p className="order-form__description-under-options">
              { section !== 'lease'
                ? I18n.t('apps.lb_showroom.detailed.order_form.sale.description_new')
                : I18n.t('apps.lb_showroom.detailed.order_form.description_new')
              }
            </p>
          </section>
        )}

        { selectedFormOption === 'provider_message' && (
          <section className="order-form__section content-section">
            <p>
               { I18n.t('apps.lb_showroom.detailed.order_form.landlord_request') }
            </p>
          </section>
        ) }

        { selectedFormOption === 'book_showcase' && (
          <section className="order-form__section content-section">
            <p>
              { I18n.t('apps.lb_showroom.detailed.order_form.specific_question')}
            </p>
          </section>
        )}


        { selectedFormOption === 'order' &&
          <div className="order-form__section-wrapper">
            <InfoForm
              buttonId={'infoButton'}
              handleInputChange={handleInputChange}
              isSubmittingOrder={isSubmittingInfo}
              locationOrdered={locationOrdered}
              siteKey={siteKey}
              submitHandler={placeOrderHandler}
              userCountryPhoneCode={userCountryPhoneCode}
              // isJeudan={isJeudan}
              // visitorToken={visitorToken}
            />
          </div>
        }

        { !isScraped &&
          <div>
            { selectedFormOption === 'provider_message' &&
              <Fragment>
                <div className="order-form__section-wrapper">
                  <ContactForm
                    buttonId={'contactButton'}
                    contactOrdered={contactOrdered}
                    handleInputChange={handleInputChange}
                    isSubmittingOrder={isSubmittingContact}
                    siteKey={siteKey}
                    submitHandler={contactFormHandler}
                    userCountryPhoneCode={userCountryPhoneCode}
                    // isJeudan={isJeudan}
                    // visitorToken={visitorToken}
                  />
                </div>
              </Fragment>
            }

            { selectedFormOption === 'book_showcase' &&
              <div className="order-form__section-wrapper">
                <ShowCaseForm
                  buttonId={'showButton'}
                  handleInputChange={handleInputChange}
                  isSubmittingOrder={isSubmittingShowCase}
                  showcaseOrdered={showcaseOrdered}
                  siteKey={siteKey}
                  submitHandler={showCaseHandle}
                  userCountryPhoneCode={userCountryPhoneCode}
                  // isJeudan={isJeudan}
                  // visitorToken={visitorToken}
                />
              </div>}
          </div>
        }

        <div className="order-form__info-wrapper info-wrapper">
          <SvgIco name="lock" size={24} />
          <span className="info-label" onClick={openInfoModal}>
            { I18n.t('apps.lb_showroom.detailed.order_form.data_protection') }
          </span>
        </div>
      </div>

      <TrustBoxContainer
        data={trustpilotWidget}
        locale={trustPilotLocale}
      />

    </div>
  );
}

export default OrderForm;
