import React from 'react';

import { SvgIco } from '../SharedComponents/Icons';
import Loader from '../SharedComponents/Loader';

import './Buttons.sass';


export const ButtonCta = ({
  active,
  isDisabled,
  shouldSubmit,
  additionalClass,
  onClick,
  text,
  type,
}) => {

  const buttonsTypeData = {
    infoButton: {
      mainText: I18n.t('apps.lb_showroom.detailed.order_form.info_button_one'),
      infoSentText: I18n.t('apps.lb_showroom.detailed.order_form.info_button_one_send'),
    },
    showButton: {
      mainText: I18n.t('apps.lb_showroom.detailed.order_form.info_button_two'),
      infoSentText: I18n.t('apps.lb_showroom.detailed.order_form.show_button_sent_ab_test'),
    },
    contactButton: {
      mainText: I18n.t('apps.lb_showroom.detailed.order_form.contact_button_one_ab_test'),
      infoSentText: I18n.t('apps.lb_showroom.detailed.order_form.contact_button_sent'),
    },
  };

  const classes = `order-form__button ${active && 'active'} ${
    additionalClass ? ' ' + additionalClass : ''
  }`;

  const buttonIcon =
    active
    ? <SvgIco name="check" size={25} />
    : isDisabled
    ? <Loader size={18} left={7} top={15} />
    : null;

  const buttonText =
    text
    ? text
    : active
    ? buttonsTypeData[type].infoSentText
    : buttonsTypeData[type].mainText;

  return shouldSubmit ? (
    <button onClick={onClick} disabled={isDisabled} className={classes}>
      { buttonIcon }
      <span>{ buttonText }</span>
    </button>
  ) : (
    <div onClick={onClick} className={classes}>
      { buttonIcon }
      <span>{ buttonText }</span>
    </div>
  );
};
